<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Clientes" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <template v-if="$helpers.hasSomePermission(['view_customer'])">
          <v-btn @click="isLoadMore=true, reset +=1, getList()" outlined :disabled="!list.length">
            <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
          </v-btn>
          <v-form @submit.prevent="getTaxpayer">
            <v-row class="mr-8 ml-2" align="center" justify="end" no-gutters>
              <v-col style="width: 170px" class="mr-2">
                <v-text-field v-model.trim="$v.filter.$model" :error="$v.filter.$error" :placeholder="`Introduce el ${$t('generals.RUT')} o nombre`" outlined required single-line dense maxlength="60" persistent-hint hide-details>
                  <template v-slot:append v-if="filter.length > 0">
                    <v-btn class="mr-n2" @click.stop="resetSearch" text x-small>
                      <v-icon class="mt-n1" color="blue-500">mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <v-btn @click="getTaxpayer" text color="blue-500">Buscar</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-btn class="ml-2" v-if="$helpers.hasSomePermission(['add_customer'])" color="blue-500" @click="dialogCustomer=true"><v-icon left>mdi-domain</v-icon>Agregar cliente</v-btn>
        </template>
      </template>
    </mini-header>
    <!-- end header -->
    <PermissionDenied class="mt-14" v-if="!$helpers.hasSomePermission(['view_customer'])" />
    <template v-else>
      <v-col cols="12" class="mt-20 pa-0">
        <skeleton-list v-if="loading" />
        <v-col cols="12" class="px-0" v-else>
            <template v-if="list.length">
              <v-simple-table :height="heightTable" fixed-header :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]" :key="reset">
                <template v-slot:default>
                  <VTableHeaders :headers="headers" @sort="setSort" />
                  <v-divider style="position: absolute;  margin-top: -6px; min-width: 100%; margin-left: -5px; z-index: 2" />
                  <tbody :key="resetScroll">
                    <tr
                      v-for="(item, i) in list"
                      :key="i"
                      v-intersect="(i + 1) === list?.length ? loadMore : ''"
                      @click="handlerItem(item)"
                      style="cursor: pointer"
                    >
                      <td class="body-2">
                        <span v-if="item?.tax_id">{{item?.tax_id}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </td>
                      <td class="body-2">
                        <span class="d-inline-block text-truncate" v-if="item.name" style="max-width: 96%;">{{item.name}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </td>
                      <td class="body-2 text-end">{{ item.created | dateTimeSecond }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
            <v-empty-state class="mt-10" v-else-if="(!list.length && !$route.query.ref) || (!list.length && $route.query.ref && filter.length) || !list.length" type="suppliers" id="clientes" :height="320" :hiddenDescription="false">
              <template v-slot:actions>
                <template v-if="!$route.query.ordering && hiddenBtnEmpty">
                  <!-- <v-btn @click="$emit('activeBtnFilter')" color="blue-500" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn> -->
                  <v-btn class="ml-2" @click="$router.push({query: {ref: 'initial'}})" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
                </template>
              </template>
            </v-empty-state>
        </v-col>
      </v-col>
    </template>

    <!-- dialog new customer -->
    <v-dialog v-model="dialogCustomer" width="700" persistent no-click-animation scrollable overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">{{ !isEdit ? 'Agregar' : 'Editar' }} cliente</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogCustomer=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-0 overflow-y-auto" style="max-height: 710px;" min-height="700" scrollable>
          <v-row class="px-5" no-gutters>
            <v-col cols="3" class="pa-0">
              <span class="grey-500--text body-1">{{$t('generals.RUT')}}</span>
              <v-text-field v-model.trim="$v.customer.taxpayer.$model" :error="$v.customer.taxpayer.$error" :readonly="isEdit" :disabled="!$helpers.hasSomePermission(['add_customer'])" required outlined single-line dense hide-details />
            </v-col>
            <v-col class="pa-0 ml-3">
              <span class="grey-500--text body-1">Nombre de la organización</span>
              <v-text-field v-model.trim="$v.customer.name.$model" :error="$v.customer.name.$error" :disabled="!$helpers.hasSomePermission(['add_customer'])" outlined required single-line dense maxlength="100" hide-details />
            </v-col>
          </v-row>
          <v-divider class="my-4" />

          <div class="px-5 pt-1">
            <v-toolbar-title class="px-0 mb-3 subtitle-2 grey-700--text font-weight-semibold">
              Agregar contactos
              <v-chip class="ml-2 px-1 font-weight-regular" x-small color="grey-background">Opcional</v-chip>
            </v-toolbar-title>

            <div v-for="(d, i) in contacts" :key="i">
              <div class="py-1 d-flex justify-space-between">
                <!-- <div class="col-4 pa-0">
                  <span class="grey-500--text body-1">Nombre</span>
                  <v-text-field v-model.trim="d.name" outlined required single-line dense maxlength="64" hide-details />
                </div> -->
                <div class="col-11 py-0 px-1">
                  <span class="grey-500--text body-1">Email</span>
                  <v-text-field v-model="$v.contacts.$each[i].email.$model" :error="$v.contacts.$each[i].email.$error" :disabled="!$helpers.hasSomePermission(['add_customer'])" outlined required single-line dense maxlength="64" hide-details />
                </div>
                <!-- <div class="col-3 pa-0" style="max-width: 290px;">
                  <span class="grey-500--text body-1">Teléfono</span>
                  <v-text-field v-model.trim="d.phone" v-mask="`${d.phone} # #### ####`" maxlength="16" outlined required single-line dense hide-details />
                </div>-->
                <div class="col-1 pa-0" style="max-width: 30px;">
                  <v-btn class="mt-5 ml-n2" v-if="contacts.length > 1" :disabled="contacts.length === 1 || !$helpers.hasSomePermission(['add_customer'])" @click="contacts.splice(i, 1)" text color="grey-700" :ripple="false"><v-icon color="grey-700">mdi-delete</v-icon></v-btn>
                </div>
              </div>
            </div>
            <v-btn @click.prevent="addRowContacts" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
              <span class="font-weight-medium body-1">Agregar otro</span>
            </v-btn>
          </div>
          <span class="px-5 mt-1 d-block grey-500--text body-1" v-if="isEdit">
            Creado el
            {{ customer.created | dateTimeSecond }}
          </span>
          <span class="px-5 mt-2 d-block grey-500--text body-1" v-if="isEdit">
            Actualizado el
            {{ customer.updated | dateTimeSecond }}
          </span>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogCustomer=false">Cancelar</v-btn>
          <v-btn color="blue-500" :loading="$store.state.customers.createLoader" @click="isEdit ? updateCustomer() : postCustomer()" :disabled="!$helpers.hasSomePermission(['add_customer'])">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new customer -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import MiniHeader from '@/components/commons/MiniHeader'
import CustomerList from '@/modules/customer/components/CustomerList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import VTableHeaders from '@/components/VTable/VTableHeaders'

export default {
  components: {
    CustomerList,
    VQueryBuilder,
    MiniHeader,
    PermissionDenied,
    VTableHeaders,
    SkeletonList,
    VEmptyState
  },
  mixins: [
    ListViewMixin
  ],
  data () {
    return {
      loading: false,
      reset: 0,
      resetScroll: 0,
      moreLoading: false,
      isLoadMore: true,
      list: [],
      isUpdated: false,
      isEdit: false,
      filter: '',
      activeFilter: false,
      customerNotExists: false,
      scroll: 0,
      customer: {
        name: '',
        taxpayer: '',
        id: '',
        updated: null,
        created: null
      },
      additionalTaxSettings: [{ description: '' }],
      additionalAddress: [{ address: '', state: '' }],
      contacts: [{ name: '', email: '', phone: '' }],
      dialogCustomer: false,
      filters: [
        {
          type: 'text',
          id: 'name',
          label: 'Nombre',
          value: null,
          category: 'a'
        },
        {
          type: 'text',
          id: 'taxpayer__tax_id',
          value: null,
          category: 'a',
          label: 'RUT'
        },
        {
          type: 'date',
          id: 'created',
          label: 'Fecha de creación',
          value: null,
          value2: null,
          category: 'b',
          operator: null,
          operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
        },
        {
          type: 'text',
          id: 'email',
          label: 'Email',
          value: null,
          category: 'a',
          operators: ['contiene']
        }
      ],
      headers: [
        { text: 'RUC', value: 'tax_id', class: 'font-weight-bold', sortable: false },
        { text: 'Nombre de la organización', value: 'name', class: 'font-weight-bold', sortable: false },
        { text: 'Creado el', value: 'created', align: 'end', sortable: false }
      ],
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Clientes'
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList,
      customersList: state => state.customers.customersList
    }),
    country () {
      return this.customer.country ? this.countriesList.find(country => country.id === this.customer.country) : null
    },
    heightTable () {
      if (this.$vuetify.breakpoint.height < 900) return 'calc(100vh - 190px)'
      // else if (this.documentsList.length < 20) return 'auto'
      return 'calc(100vh - 200px)'
    },
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (!this.customersList.length) return false
      return false
    }
  },
  watch: {
    'customer.country' () {
      if (this.customerNotExists) {
        this.customer.state = ''
        this.customer.taxpayer = ''
      }
    },
    dialogCustomer (val) {
      if (!val) {
        this.customer.name = ''
        this.customer.id = ''
        this.customer.updated = null
        this.customer.created = null
        this.customer.taxpayer = ''
        this.contacts = [{ name: '', email: '', phone: '' }]
        this.filter = ''
        this.isEdit = false
        this.$v.filter.$reset()
        this.$v.customer.$reset()
      }
    }
  },
  created () {
    if ((Object.keys(this.$route?.query)?.length)) this.getList()
  },
  methods: {
    resetSearch () {
      this.filter = ''
      this.getList()
      this.$v.filter.$reset()
    },
    getList () {
      this.loading = true
      this.isCreated = true
      this.$store.dispatch('customers/LIST', {
        resource: 'customers',
        query: {
          ...this.$route.query,
          ...(this.filter.length > 0 && {q: this.filter})
        }
      })
      .then(() => {
        this.list = this.customersList
      })
      .finally(() => {
        this.loading = false
      })
      },
    loadMore (entries, observer, isIntersecting) {
        if (isIntersecting && this.isLoadMore) {
          this.moreLoading = true
          this.$store.dispatch('customers/LIST', {
            resource: 'customers',
            query: {
              ...this.$route.query,
              last_id: this.customersList[this.customersList.length - 1]?.id
            }
          })
          .then((response) => {
            this.isLoadMore = !!response.data.length
            if (this.isLoadMore) {
              this.resetScroll += 1
              this.list = [...this.list, ...response.data]
              this.pagination = this.list.length
            }
          })
          .finally(() => {
            this.moreLoading = false
          })
        }
      },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    handlerItem (item = {}) {
      this.customer.id = item.id
      this.customer.name = item.name
      this.customer.taxpayer = item.tax_id
      this.customer.updated = item.updated
      this.customer.created = item.created
      this.contacts = []
      item.emails.forEach((item) => {
        this.contacts.push({ email: item })
      })
      this.isEdit = true
      this.dialogCustomer = true
    },
    getTaxpayer () {
      this.$v.filter.$touch()

      if (this.$v.filter.$invalid) {
        return false
      }

      this.reset += 1
      this.$store.dispatch('customers/LIST', {
        resource: 'customers',
        query: { q: this.filter }
      })
      .then((response) => {
        this.list = response.data
      })
      .catch(() => {
        this.$dialog.message.error('Cliente no encontrado')
      })
    },
    addRowContacts () {
      this.contacts.push({
        name: '',
        email: '',
        phone: ''
      })
    },
    postCustomer () {
      this.$v.customer.$touch()
      if (this.$v.customer.$invalid) return false

      if (this.contacts[0].email.length) {
        this.$v.contacts.$touch()
        if (this.$v.contacts.$invalid) return false
      }

      this.$store.dispatch(`accounts/CREATE`, {
        resource: 'customers',
        payload: {
          name: this.customer.name,
          tax_id: this.customer.taxpayer,
          emails: this.contacts.map(({email}) => email)
        }
      })
      .then(() => {
        this.$dialog.message.info('El cliente fue creado con éxito.')
        this.dialogCustomer = false
        this.getList()
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error')
      })
    },
    updateCustomer () {
      this.$v.customer.$touch()
      if (this.$v.customer.$invalid) return false

      if (this.contacts[0].email.length) {
        this.$v.contacts.$touch()
        if (this.$v.contacts.$invalid) return false
      }

      this.$store.dispatch(`accounts/UPDATE`, {
        resource: 'customers',
        id: this.customer.id,
        payload: {
          name: this.customer.name,
          tax_id: this.customer.taxpayer,
          emails: this.contacts.map(({email}) => email)
        }
      })
      .then(() => {
        this.$dialog.message.info('El cliente fue actualizado con éxito.')
        this.dialogCustomer = false
        this.getList()
        if (this.isEdit) this.isEdit = false
      })
      .catch(() => {
        this.$dialog.message.error('Ha ocurrido un error')
      })
    }
  },
  validations: {
    filter: {
      required
    },
    customer: {
      name: {
        required,
        maxLength: maxLength(100)
      },
     taxpayer: {
        required,
        maxLength: maxLength(32)
      }
    },
    contacts: {
      $each: {
        email: {
         required,
          email,
          maxLength: maxLength(64)
        }
      }
    }
  }
}
</script>