<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0" v-for="(rule, i) in currentRules" :key="`${rule}-${i}`">
      <div class="px-5 py-4">
        <span class="py-2 d-block font-weight-medium body-1 grey-700--text">{{ rule.childrens[0]?.title ?? '' }}</span>
        <div v-for="children in rule.childrens.filter((item) => item.isMain ?? true)" :key="children.id">
          <v-row class="my-3" align="center" no-gutters>
            <v-col class="pa-0 body-1 grey-500--text">
              <span v-if="children.label === 'Número de folio'">{{ $t('generals.Número de folio') }}</span>
              <span v-else-if="children.label === 'RUT'">{{ $t('generals.RUT') }}</span>
              <span v-else-if="children.label === 'FechaSUNAT'">{{ $t('generals.FechaSUNAT') }}</span>
              <span v-else>{{children.label}}</span>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-row class="justify-end mt-n5 mr-n1" no-gutters>
                <v-switch :value="rulesAddedList.find(item => item.id === children.id)" @change="handleSwitch($event, children)" color="green-500" hide-details :ripple="false" />
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12" class="px-1 py-0" v-if="rulesAddedList.find(item => item.id === children.id)">
            <v-row>
              <v-col class="pb-5">
                <v-query-builder-rule :rule.sync="children" @remove="remove" />
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
      <v-divider v-if="i !== currentRules.length - 1" class="" />
    </v-col>
  </v-row>
</template>
<script>
import VQueryBuilderRule from './VQueryBuilderRule'
import { groupBy } from 'lodash'

export default {
  components: {
    VQueryBuilderRule
  },
  props: {
    rules: {
      type: Array,
      default: () => []
    },
    queryObject: {
      type: Object,
      default: () => {}
    },
    isActiveFilter: {
      type: Boolean,
      default: false
    },
    cleanfilters: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    srule: null,
    selectedRule: {},
    backupQueryChildren: [],
    rulesAddedList: [],
    isValue: false
  }),
  computed: {
    currentRules () {
      return Object.entries(groupBy(this.rules, 'category')).map(([key, value]) => {
        return {
          type: key,
          childrens: value
        }
      })
    }
  },
  watch: {
    isActiveFilter: {
      handler (val) {
        if (val) {
          this.addRule()
        }
      },
      imediate: true,
      deep: true
    },
    cleanfilters: {
      handler (val) {
        if (val) {
          this.rulesAddedList = []
        }
      },
      imediate: true,
      deep: true
    }
  },
  mounted () {
    this.rules.forEach(item => {
      if (item?.value?.length || item?.value2 || item?.value2?.length) {
        this.rulesAddedList.push(item)
      }
    })
  },
  methods: {
    handleSwitch (isActive, item = {}) {
      this.srule = isActive ? item.id : null
      this.selectedRule = item

      if (isActive && !this.rulesAddedList.find((rule) => rule.id === item.id)) {
        this.rulesAddedList.push(item)
      } else {
        this.rulesAddedList = this.rulesAddedList.filter(r => r.id !== item.id)
      }
    },
    addRule () {
      this.backupQueryChildren = []
      let child = {}
      if (this.rulesAddedList.length) {
        this.rulesAddedList.forEach(rule => {
          if ((!Array.isArray(rule.value) && !['', null, 'null'].includes(rule.value)) || (Array.isArray(rule.value) && rule.value.length > 0) || ((!['', null, 'null'].includes(rule.value2)))) {
            this.isValue = true

            if (rule.operator === 'rango') {
              if (rule.value) {
                if (rule.type === 'combined') {
                  child = this.setChild('number', '', rule.value)
                  const foundIndex = this.backupQueryChildren?.findIndex((c) => c.query?.rule === 'number' && c.query.operator === child.query.operator)
                  this.checkRule(child, foundIndex)
                } else {
                  child = this.setChild(rule.id, rule?.operators[2], rule.value)
                  const foundIndex = this.backupQueryChildren?.findIndex((c) => c.query?.rule === rule.id && c.query.operator === child.query.operator)
                  this.checkRule(child, foundIndex)
                }
              }
              if (rule.value2) {
                child = this.setChild(rule.id, rule?.operators[1], rule.value2)
                const foundIndex = this.backupQueryChildren?.findIndex((c) => c.query?.rule === rule.id && c.query.operator === child.query.operator)
                this.checkRule(child, foundIndex)
              }
            } else {
              child = {
                type: 'query-builder-rule',
                query: {
                  rule: rule.id,
                  ...(!!rule?.operators?.length && { operator: rule?.operator ?? rule?.operators[0] }),
                  ...((!!rule?.operator_branch?.length || !!rule?.operator_cpos?.length) && { operator: rule?.operator_cpos?.length ? rule?.operator_cpos : rule?.operator_branch }),
                  value: rule.value,
                  ...(rule?.isCombined && {value2: rule.value2, dependent: rule.dependent})
                }
              }
              const foundIndex = this.backupQueryChildren?.findIndex((c) => c.query?.rule === rule.id)
              this.checkRule(child, foundIndex)
            }
          }
        })
        this.rulesAddedList = this.rulesAddedList.filter((item) => this.backupQueryChildren.map(({query}) => query).find((q) => q.rule === item.id))
       this.$emit('update:queryObject', {...{children: this.backupQueryChildren}, ...{logicalOperator: "all"}})
      } else {
        this.remove()
      }
      this.$emit('update:isActiveFilter', false)

      this.isValue = false
    },
    checkRule (child = {}, foundIndex = null) {
      if (foundIndex < 0) {
        this.backupQueryChildren.push(child)
      } else {
        this.backupQueryChildren[foundIndex] = child
      }
    },
    setChild (id = null, operators = null, value = null) {
      return {
        type: 'query-builder-rule',
        query: {
          rule: id,
          operator: operators,
          value: value
        }
      }
    },
    remove () {
      this.currentRules.forEach(element => {
        element.childrens.filter(item => {
          if (item.id === this.selectedRule.id) {
            item.value = null
            item.value2 = null
          }
        })
      })
      this.rulesAddedList = this.rulesAddedList.filter(({value}) => value)
      const foundIndex = this.backupQueryChildren?.findIndex((c) => c.query?.rule === this.selectedRule.id)
      this.backupQueryChildren.splice(this.backupQueryChildren.indexOf(foundIndex), 1)
      this.backupQueryChildren = [...this.backupQueryChildren]
      this.$emit('update:queryObject', {...{children: this.backupQueryChildren}, ...{logicalOperator: "all"}})
    }
  }
}
</script>