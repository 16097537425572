<template>
  <v-row no-gutters class="ma-0" :key="$store.state.base.isExpandNavigationDrawer">
    <v-col :class="`grey-background pt-4 pb-3 ${$store.state.base.isExpandNavigationDrawer ? 'pagination-mode': 'pagination' }`" v-if="routeList.includes($route.name)">
      <v-row no-gutters align="center">
        <v-col class="grey-300--text body-2">
          {{ title }} {{ count | number }} {{ count === 10000 ? '+' : '' }}
        </v-col>
        <v-col class="text-right">
          <v-btn class="subtitle-2" @click="previous" :disabled="page === 1" color="grey-700" text :ripple="false">
            <v-icon class="mr-0">mdi-chevron-left</v-icon>
          </v-btn>
          <v-icon class="mx-5" color="grey-700" size="8">mdi-circle</v-icon>
          <v-btn class="subtitle-2 font-weight-medium pa-0 btnPage" color="blue-500" icon small :ripple="false">{{ page }}</v-btn>
          <v-icon class="mx-5" color="grey-700" size="8 ">mdi-circle</v-icon>
          <v-btn class="subtitle-2" @click="next" :disabled="disabledNext" color="grey-700" text :ripple="false">
            <v-icon class="ml-0">mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="grey-background text-center mt-6 py-2 ml-n4" :class="[`${$route.name !== 'AccountMembers' ? 'pagination' : 'paginationMember'}`]" v-else :style="$store.state.base.isExpandNavigationDrawer ? `${$route.name !== 'AccountMembers' ? 'margin-left: -12px; width: calc(100% - 260px)' : ''}` : ''">
      <v-btn class="subtitle-2" @click="previous" :disabled="page === 1" color="grey-700" text :ripple="false">
        <v-icon class="mr-3">mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-icon class="mx-5" color="grey-700" size="8 ">mdi-circle</v-icon>
      <v-btn class="subtitle-2 font-weight-medium pa-0 btnPage" color="blue-500" icon small :ripple="false">{{ page }}</v-btn>
      <v-icon class="mx-5" color="grey-700" size="8 ">mdi-circle</v-icon>
      <v-btn class="subtitle-2" @click="next" :disabled="disabledNext" color="grey-700" text :ripple="false">
        Siguiente <v-icon class="ml-3">mdi-chevron-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  mixins: [
    ListViewMixin, GenericViewMixin
  ],
  props: {
    titleShowAll: {
      type: String,
      default: 'Ver todos los documentos'
    },
    redirect: {
      type: String,
      default: null
    },
    count: {
      default: null
    },
    pageSize: {
      default: null
    },
    title: {
      type: String,
      default: 'Documentos'
    }
  },
  data: () => ({
    currentPage: null,
    totalRegistrosPerPagina: 0,
    routeList: ['SummariesList', 'DocumentsList', 'PurchaseList', 'DocumentsBatchRetrieve']
  }),
  computed: {
    disabledNext () {
      return this.routeList.includes(this.$route.name) ? ((parseInt(this.$route.query.page) === this.mainTotalPages) || (this.pageSize >= this.count)) || (this.count <= 20) : !this.count
    },
    mainTotalPages () {
      const number = this.count / (this.pageSize ?? 20)
      return this.count > (this.pageSize ?? 20) ? Math.ceil(number) : 1
    }
  },
  methods: {
    previous () {
      if (this.page > 1) this.page -= 1
    },
    next () {
      const validate = this.routeList.includes(this.$route.name) ? this.page <= this.mainTotalPages : this.count
      if (validate) this.page += 1
    }
  }
}
</script>